<template>
  <div>
    <b-form-row>
      <b-col>
        <b-form-group>
          <div class="floating-select-field">
            <v-select
              label="name"
              :id="`street-name`"
              :filterable="false"
              :class="{
                'is-value-exist': formData.streetName || focusedStreetName,
                'is-invalid': !formData.streetName && formSubmitted,
              }"
              :options="streetNames"
              v-model="formData.streetName"
              @open="focusedStreetName = true"
              @close="focusedStreetName = false"
              @search="onSearchStreetNames"
              @input="getStreetNumbers($event)"
            >
              <template slot="no-options" slot-scope="{ search }">
                {{
                  search && search.trim()
                    ? `No street names found.`
                    : `Start typing your street name, and select it from the
                dropdown.`
                }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.name }}
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.name }}
              </template>
            </v-select>
            <label :for="`street-name`">Street name</label>
            <b-form-invalid-feedback class="d-block" v-if="!formData.streetName && formSubmitted">Street name required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <div class="floating-select-field">
            <select
              v-model="formData.streetNumber"
              @input="formData.streetNumber = $event.target.value"
              @change="getUnitNumbers($event.target.value)"
              :id="`street-number`"
              :disabled="!formData.streetName"
              class="form-control"
              :class="{
                'is-value-exist': formData.streetNumber,
                'is-invalid': !formData.streetNumber && formSubmitted,
              }"
            >
              <option v-for="(streetNumber, si) in streetNumbers" :value="streetNumber" :key="si">
                {{ streetNumber }}
              </option>
            </select>
            <label :for="`street-number`">Street number</label>
            <b-form-invalid-feedback class="d-block" v-if="!formData.streetNumber && formSubmitted">Street number required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <div class="floating-select-field" v-if="unitNumbers">
            <select
              v-model="formData.unitNumber"
              @input="formData.unitNumber = $event.target.value"
              @change="formData.unitNumber = $event.target.value"
              :id="`unit-number`"
              :disabled="!formData.streetNumber || !unitNumbers.length"
              class="form-control"
              :class="{
                'is-value-exist': formData.unitNumber,
                'is-invalid': unitNumbers.length && !formData.unitNumber && formSubmitted,
              }"
            >
              <option v-for="(unitNumber, ui) in unitNumbers" :value="unitNumber" :key="ui">
                {{ unitNumber }}
              </option>
            </select>
            <label :for="`unit-number`">Apt / unit number</label>
            <b-form-invalid-feedback class="d-block" v-if="unitNumbers.length && !formData.unitNumber && formSubmitted"
              >Apt / unit number required.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
export default {
  name: 'PropertyForm',
  data() {
    return {
      formData: {
        streetName: null,
        streetNumber: null,
        unitNumber: null,
      },
      streetNames: [],
      streetNumbers: [],
      unitNumbers: [],
      focusedStreetName: false,
      formSubmitted: false,
    }
  },
  methods: {
    onSearchStreetNames(search, loading) {
      search = search ? search.trim() : search
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      const postObj = {
        tenantId: 0,
        streetName: search,
        streetNumber: '',
      }
      vm.$store
        .dispatch('widgets/getStreetNames', postObj)
        .then((res) => {
          vm.streetNames = res && res.data ? res.data : []
          loading(false)
        })
        .catch(() => {
          vm.streetNames = []
          loading(false)
        })
    }, 50),
    async getStreetNumbers(streetName) {
      if (streetName) {
        const postObj = {
          tenantId: 0,
          streetName: streetName,
          streetNumber: '',
        }
        this.formData.streetNumber = ''
        this.formData.unitNumber = ''
        this.unitNumbers = []
        this.streetNumbers = []
        const res = await this.$store.dispatch('widgets/getStreetNumbers', postObj)
        this.streetNumbers = res && res.data ? res.data : []
      } else {
        this.formData.streetNumber = ''
        this.formData.unitNumber = ''
        this.unitNumbers = []
        this.streetNumbers = []
      }
    },
    async getUnitNumbers(streetNumber) {
      if (streetNumber) {
        const postObj = {
          tenantId: 0,
          streetName: this.formData.streetName,
          streetNumber: streetNumber,
        }
        this.formData.unitNumber = ''
        this.unitNumbers = []
        const res = await this.$store.dispatch('widgets/getUnitNumbers', postObj)
        this.unitNumbers = res && res.data ? res.data : []
      }
    },
    validateForm() {
      this.formSubmitted = true
      const streetName = !!this.formData.streetName
      const streetNumber = !!this.formData.streetNumber
      const unitNumber = this.unitNumbers.length ? !!this.formData.unitNumber : true
      const result = streetName && streetNumber && unitNumber
      return result ? this.formData : result
    },
    resetForm() {
      this.formSubmitted = false
      this.formData = {
        streetName: null,
        streetNumber: null,
        unitNumber: null,
      }
    },
  },
}
</script>
